@import 'assets/styles/_imports';

.alert {
	width: 280px;
	border-radius: 12px;
	background-color: $backgroundPrimary;

	&__header {
		padding: 16px 12px;
	}

	&__title {
		font-size: 16px;
		line-height: 20px;
		font-weight: 500;
		color: $textPrimary;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__subtitle {
		font-size: 14px;
		line-height: 20px;
		color: $textPrimaryOpacity40;
		text-align: center;
		margin-top: 8px;
		text-align: center;
	}

	&__btns {
		display: flex;
		flex-direction: column;
	}

	&__btn {
		font-size: 14px;
		line-height: 32px;
		color: $textPrimaryOpacity30;
		font-weight: 500;
		background-color: transparent;
		transition: 0.4s background-color;
		border-top: 1px solid $borderPrimaryColorOpacity06;

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:focus,
		&:active {
			background-color: $backgroundInverseOpacity12;
		}

		&--primary {
			color: $primary;
		}
	}
}
