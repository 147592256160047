@import 'assets/styles/imports';

.control-panel {
	$block: '.control-panel';

	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	padding: 7px 12px 12px;
	background-color: #1a191c;
	z-index: 2;

	&__stream-ended {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-height: 50px;
		background-color: #38383d;
		text-align: center;
		font-size: 17px;
		line-height: 50px;
		font-weight: 700;
		color: $danger;
	}

	&__left,
	&__right-group {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		max-width: 88px;
		transition: max-width 0.5s, opacity 0.5s;

		* {
			opacity: 1;
			transition: opacity 0.5s;
		}

		&.fade-exit,
		&.fade-exit-done {
			max-width: 0;
			margin: 0;

			* {
				opacity: 0;
			}
		}
	}

	&__left,
	&__right-group,
	&__right {
		transition: max-width 0.5s, opacity 0.5s;

		* {
			opacity: 1;
			transition: opacity 0.5s;
		}

		&.fade-exit,
		&.fade-exit-done {
			max-width: 0;
			margin: 0;

			* {
				opacity: 0;
			}
		}
	}

	&__left {
		position: relative;
		margin-right: 12px;

		&:empty {
			margin-right: 0;
		}

		#{$block}__btn {
			margin-right: 0;
		}
	}

	&__center {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-grow: 1;
		// overflow: hidden;
	}

	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		max-width: 88px;
		margin-left: 16px;
		margin-top: auto;

		&:empty {
			margin-left: 0;
		}
	}

	&__right-group {
		position: relative;
		max-width: 36px;
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 32px;
		height: 32px;
		margin-right: 12px;
		padding: 0;
		background: $backgroundInverseOpacity06;
		border-radius: 16px;
		user-select: none;
		cursor: pointer;

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:last-child {
			margin-right: 0;
		}

		* {
			pointer-events: none;
		}

		&--emotions {
			position: relative;
			#{$block}__btn-ico {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				transition: transform 0.35s;
			}
		}

		&--emotions#{&}--active {
			#{$block}__btn-ico {
				transform: scale(0.9);
			}
		}
	}

	&__btn-container {
		position: relative;

		.control-panel__btn {
			margin-right: 0;
		}
	}

	&__icon-white {
		path {
			fill: #ffffff;
		}
	}

	&__onboarding {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
	}

	&__ban-alert {
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		letter-spacing: -0.4;
		color: $textPrimaryOpacity20;
	}

	.tooltip {
		max-width: 300px;
	}
}
