@import 'assets/styles/_imports';

.chatname-form {
	$block: '.chatname-form';

	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;

	&--no-valid {
		#{$block}__input {
			border-bottom-color: $danger;
		}

		#{$block}__warn {
			opacity: 1;
			visibility: visible;
		}
	}

	&--no-empty {
		#{$block}__label {
			top: -20px;
			font-size: 14px;
		}
	}

	&__btn {
		margin-top: auto;
	}

	&__title {
		font-size: 16px;
		line-height: 21px;
		color: $textPrimary;
		margin-bottom: 10px;
	}

	&__field {
		position: relative;
		width: 100%;
	}

	&__input {
		display: block;
		width: 100%;
		padding: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 36px;
		color: $textPrimary;
		background: none;
		border: none;
		border-bottom: 2px solid #38383d;
		border-radius: 0;
		transition: border $duration;

		@include dsk-small {
			font-size: 16px;
		}

		@include tablet {
			font-size: 16px;
		}

		&:focus {
			border-bottom-color: $primary;
		}

		@include placeholder {
			color: transparent;
		}
	}

	&__input:focus ~ &__label {
		top: -28px;
		font-size: 14px;

		@include dsk-small {
			font-size: 12px;
		}

		@include tablet {
			font-size: 12px;
		}
	}

	&__label {
		position: absolute;
		top: 0;
		left: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 36px;
		color: #919193;
		transition: top $duration, font $duration;
		user-select: none;
		pointer-events: none;
		z-index: 1;

		@include dsk-small {
			font-size: 16px;
		}

		@include tablet {
			font-size: 16px;
		}
	}

	&__warn {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $danger;
		margin-top: 6px;
		opacity: 0;
		visibility: hidden;
		transition: opacity $duration, visibility $duration;
	}
}
