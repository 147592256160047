@import 'assets/styles/imports';

.chat {
	$block: '.chat';

	width: 100%;
	height: 100%;
	background-color: $backgroundPrimary;
	overflow: hidden;
	position: relative;

	&--messages-wpinned {
		#{$block}__message {
			&:nth-child(1) {
				padding-top: 116px;
			}
		}

		& + .main-bottom #{$block}__emoji {
			em-emoji-picker {
				height: calc(100vh - 175px);
			}
		}
	}

	&--overflow {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(#000, 0.4);
			z-index: 3;
		}
	}

	&__axis-y {
		display: flex;
		width: 100%;
		height: 100%;
		padding: 16px 0 0;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		opacity: 0;
		visibility: hidden;
		transition: $duration opacity, $duration visibility;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__axis-y::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&__messages {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
	}

	&__message {
		&:first-child {
			margin-top: auto;
			padding-top: 72px;

			#{$block}__message-date {
				padding-top: 8px;
			}
		}

		&:last-child {
			padding-bottom: 12px;
		}
	}

	&__message-date {
		display: flex;
		justify-content: center;
		padding-top: 24px;
		padding-bottom: 14px;
	}

	&__message-date-item {
		height: 22px;
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		line-height: 22px;
		color: $textPrimaryOpacity60;
		background-color: $textPrimaryOpacity60;
		border-radius: 11px;
	}

	&__message-container {
		display: flex;
		align-items: flex-end;
		width: 100%;
		margin-top: 10px;
		padding-left: 12px;
		padding-right: 12px;
		user-select: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background: #797979;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: 0.4s opacity, 0.4s visibility;
		}

		&--lighted {
			&:after {
				opacity: 0.3;
				visibility: visible;
			}
		}

		&--no-avatar + & {
			margin-top: 4px;
		}

		&--is-hidden {
			#{$block}__message-right {
				max-width: calc(100% - 108px);
			}

			#{$block}__message-body {
				opacity: 0.5;

				&:after {
					@include ps-elem;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: -26px;
					width: 18px;
					height: 18px;
					background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6654 7.00004C13.6654 10.6819 10.6806 13.6667 6.9987 13.6667C3.3168 13.6667 0.332031 10.6819 0.332031 7.00004C0.332031 3.31814 3.3168 0.333374 6.9987 0.333374C10.6806 0.333374 13.6654 3.31814 13.6654 7.00004ZM6.9987 12.5556C10.0669 12.5556 12.5543 10.0683 12.5543 7.00004C12.5543 3.93179 10.0669 1.44449 6.9987 1.44449C3.93045 1.44449 1.44314 3.93179 1.44314 7.00004C1.44314 10.0683 3.93045 12.5556 6.9987 12.5556Z' fill='%23E50914'/%3E%3Cpath d='M3.66536 7.00004C3.66536 6.69322 3.9141 6.44449 4.22092 6.44449H9.77648C10.0833 6.44449 10.332 6.69322 10.332 7.00004C10.332 7.30687 10.0833 7.5556 9.77648 7.5556H4.22092C3.9141 7.5556 3.66536 7.30687 3.66536 7.00004Z' fill='%23E50914'/%3E%3C/svg%3E%0A")
						no-repeat center;
					background-size: contain;
					pointer-events: none;
					z-index: 1;
				}
			}
		}

		&--right {
			justify-content: flex-end;

			#{$block}__message-left {
				order: 2;
				margin-right: 0;
				margin-left: 8px;
			}

			#{$block}__message-right {
				order: 1;
				align-items: flex-end;
				max-width: calc(100% - 66px);
			}

			#{$block}__message-body {
				background-color: $blue;
			}

			#{$block}__message-text a {
				color: $textPrimary;
				border-bottom-color: $borderPrimaryColor;

				@include tablet {
					&:hover {
						border-color: transparent;
					}
				}
			}

			#{$block}__message-sticker {
				margin-left: auto;
			}

			#{$block}__submenu-reactions {
				left: auto;
				right: 0;
			}

			.link-preview__sitename {
				color: $textPrimary;
			}

			.reactions {
				margin-left: auto;

				&__items {
					justify-content: flex-end;
				}
			}

			&#{$block}__message-container--reactions {
				#{$block}__message-text {
					display: block;
				}

				.reactions {
					&__item-btn {
						color: $textPrimaryOpacity60;
						background: $backgroundInverseOpacity20;

						&--selected {
							color: $textSecond;
							background: $backgroundInverse;
						}
					}
				}
			}

			&#{$block}__message-container--is-hidden {
				#{$block}__message-body:after {
					right: auto;
					left: -26px;
				}

				#{$block}__message-right {
					max-width: calc(100% - 92px);
				}
			}

			&#{$block}__message-container--sticker {
				&#{$block}__message-container--reactions {
					.reactions {
						&__item-btn {
							color: $textPrimaryOpacity60;
							background: #323136;

							&--selected {
								color: $textPrimary;
								background: $blue;
							}
						}
					}
				}
			}
		}

		&--highlight {
			padding: 14px 0;

			#{$block}__message-body {
				padding: 0;
				background-color: transparent;
			}

			> #{$block}__message-date,
			& + #{$block}__message #{$block}__message-date {
				padding-top: 0;
			}
		}

		&--poll-results {
			#{$block}__message-body {
				background-color: $primary;
				// background-color: #1d1f23;
			}

			// &:not(#{$block}__message--right) {
			// 	.poll-results {
			// 		&__text {
			// 			color: $textPrimaryOpacity80;
			// 		}

			// 		&__option-title {
			// 			color: $textPrimary;
			// 		}

			// 		&__subtext,
			// 		&__votes {
			// 			color: $textPrimaryOpacity60;
			// 		}
			// 	}

			// 	.poll-option-voting {
			// 		&__number {
			// 			color: $textPrimaryOpacity60;
			// 		}

			// 		&__indicate {
			// 			span {
			// 				background-color: $textPrimaryOpacity60;
			// 			}
			// 		}
			// 	}
			// }
		}

		&--sticker {
			#{$block}__message-body {
				padding: 0;
				background: none;
			}

			#{$block}__message-text {
				align-items: flex-end;
			}
		}

		&--reactions {
			#{$block}__message-text {
				display: block;
			}

			#{$block}__message-time {
				margin-top: 4px;
			}
		}

		&--submenu {
			z-index: 3;
		}

		&--bet {
			#{$block}__message-header {
				margin-bottom: 8px;
			}

			.bet {
				margin-bottom: 8px;
			}
		}
	}

	&__message-not-viewed {
		display: block;
		width: 100%;
		line-height: 30px;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.6);
		background-color: #e4e4e4;
		margin-top: 10px;
		text-align: center;
	}

	&__message-header {
		display: flex;
		align-items: center;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 3px;
		}
	}

	&__message-left {
		width: 28px;
		margin-right: 8px;
	}

	&__message-avatar {
		position: relative;
		width: 100%;
		padding-top: 100%;
		border-radius: 50%;
	}

	&__message-avatar-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
		border-radius: 50%;
		pointer-events: none;
	}

	&__message-right {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: calc(100% - 66px);
	}

	&__message-author {
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: $textPrimaryOpacity60;
		overflow: hidden;
		text-overflow: ellipsis;

		@include dsk-small {
			font-size: 10px;
			line-height: 12px;
		}

		@include tablet {
			font-size: 10px;
			line-height: 12px;
		}

		& + #{$block}__message-author-status {
			margin-left: 4px;
		}
	}

	&__message-author-status {
		height: 18px;
		flex-shrink: 0;
		padding-left: 6px;
		padding-right: 6px;
		font-size: 13px;
		line-height: 18px;
		color: $textSecond;
		border-radius: 9px;

		&--top {
			background-color: #f0f0f0;
		}

		&:not(:last-child) {
			margin-right: 4px;
		}
	}

	&__message-author-admin {
		position: relative;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: #949494;
		margin-left: 4px;
		display: flex;
		align-items: center;

		@include dsk-small {
			font-size: 10px;
			line-height: 12px;
		}

		@include tablet {
			font-size: 10px;
			line-height: 12px;
		}

		&::before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			position: relative;
			margin-right: 5px;
			margin-bottom: 3px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.79 12.79'%3E%3Cpath d='M3.05,11.3H4.22a.37.37,0,0,1,.24.1l.83.82a1.6,1.6,0,0,0,1.11.57,1.58,1.58,0,0,0,1.1-.57l.83-.82a.33.33,0,0,1,.24-.1H9.74a1.64,1.64,0,0,0,1.18-.38,1.67,1.67,0,0,0,.38-1.19V8.57a.33.33,0,0,1,.1-.24l.82-.83a1.58,1.58,0,0,0,.57-1.11,1.62,1.62,0,0,0-.57-1.11l-.82-.82a.37.37,0,0,1-.1-.24V3.05a1.61,1.61,0,0,0-.38-1.17,1.56,1.56,0,0,0-1.18-.4H8.57a.3.3,0,0,1-.24-.1L7.5.56A1.59,1.59,0,0,0,6.39,0a1.61,1.61,0,0,0-1.1.56l-.83.82a.33.33,0,0,1-.24.1H3.05a1.57,1.57,0,0,0-1.17.39,1.58,1.58,0,0,0-.39,1.18V4.22a.33.33,0,0,1-.1.24l-.83.82A1.65,1.65,0,0,0,0,6.39,1.61,1.61,0,0,0,.56,7.5l.83.83a.3.3,0,0,1,.1.24V9.73a1.6,1.6,0,0,0,.39,1.18A1.57,1.57,0,0,0,3.05,11.3ZM5.78,9.16a.66.66,0,0,1-.32-.08.76.76,0,0,1-.27-.24L3.87,7.27a.72.72,0,0,1-.17-.45.57.57,0,0,1,.17-.42.57.57,0,0,1,.42-.17.55.55,0,0,1,.26.06.7.7,0,0,1,.23.2l1,1.22L8,4.22a.62.62,0,0,1,.52-.33.64.64,0,0,1,.43.16.5.5,0,0,1,.18.4.52.52,0,0,1,0,.21.9.9,0,0,1-.11.22l-2.58,4A.69.69,0,0,1,5.78,9.16Z' fill='%230d6eff'/%3E%3C/svg%3E");
		}
	}

	&__message-body {
		position: relative;
		max-width: 100%;
		padding: 6px 12px;
		background: $backgroundInverseOpacity06;
		border-radius: 10px;
		transition: opacity $duration;
	}

	&__message-text {
		display: flex;
		align-items: flex-end;
		font-size: 14px;
		line-height: 20px;
		white-space: pre-wrap;
		color: #ffffff;
		word-break: break-word;
		color: $textPrimary;

		a {
			border-bottom: 1px solid $primary;
			transition: 0.4s border-color;
			color: $primary;

			@include tablet {
				&:hover {
					border-color: transparent;
				}
			}
		}

		&--multiline {
			display: block;

			#{$block}__message-time {
				margin-top: 4px;
			}
		}

		&--link-preview {
			a {
				color: $textPrimary;
				border-bottom-color: $borderPrimaryColor;
			}
		}
	}

	&__message-text-item {
		margin-right: auto;
	}

	&__message-sticker {
		width: 96px;
		height: 96px;
	}

	&__message-sticker-img {
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__message-replay {
		display: flex;
		align-items: center;
		position: relative;
		max-width: 100%;
		margin-bottom: 4px;
		text-align: left;
		padding-left: 6px;
		border-left: 1px solid #ffffff;
		cursor: pointer;
		z-index: 2;

		#{$block}__message-author {
			font-size: 14px;
			line-height: 16px;
			margin-bottom: 0;
			color: #fff;
			font-weight: 500;
			margin-bottom: 2px;
		}

		#{$block}__message-text {
			display: block;
			padding: 0;
			color: $textPrimaryOpacity70;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			white-space: nowrap;
			text-overflow: ellipsis;
			background: none;
			border-radius: 0;
			overflow: hidden;
		}
	}

	&__message-replay-img {
		width: 36px;
		height: 36px;
		margin-right: 4px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		& + #{$block}__message-replay-content {
			width: calc(100% - 40px);
		}
	}

	&__message-replay-content {
		width: 100%;
	}

	&__message-time {
		position: relative;
		margin-left: 9px;
		text-align: right;
		font-size: 8px;
		line-height: 12px;
		white-space: nowrap;
		color: $textPrimaryOpacity60;
	}

	&__textarea-wrap {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;

		&--warning {
			#{$block}__textarea-item {
				border-color: $primary;
			}

			#{$block}__text-length {
				color: $primary;
			}
		}
	}

	&__textarea-item {
		display: block;
		width: 100%;
		height: 32px;
		min-height: 32px;
		max-height: 78px;
		padding: 5px 48px 5px 12px;
		color: $textPrimaryOpacity80;
		font: 400 16px/20px $font-Montserrat;
		background: $backgroundInverseOpacity06;
		border: 1px solid $borderPrimaryColorOpacity06;
		transition: border $duration, background $duration;
		border-radius: 17px;
		overflow: hidden auto;
		-webkit-user-select: text;
		-ms-overflow-style: none;
		scrollbar-width: none;
		resize: none;

		&:focus {
			outline: none;
		}

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&--wtext {
			padding-right: 75px !important;
		}

		@include placeholder {
			color: $textPrimaryOpacity30;
			font-size: 13px;
		}
	}

	&__submenu {
		position: absolute;
		width: 282px;
		top: calc(100% + 60px);
		left: 0;
		background-color: #212023;
		border-radius: 12px;
		opacity: 0;
		visibility: hidden;
		z-index: 103;

		@media screen and (max-width: 355px) {
			width: 228px;
		}

		&--top {
			top: auto;
			bottom: calc(100% + 10px);
		}

		&--right {
			left: auto;
			right: 0;
		}

		&--visible {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.2s 0.1s ease-in, visibility 0.2s 0.1s ease-in;
		}
	}

	&__submenu-items {
		display: flex;
		flex-wrap: wrap;

		&--have-small-2,
		&--have-small-3 {
			#{$block}__submenu-item--small {
				padding: 0;
				border-left: 1px solid $borderPrimaryColorOpacity06;
				border-bottom: 1px solid $borderPrimaryColorOpacity06;

				&:first-child {
					border-left: none;
				}

				#{$block}__submenu-btn {
					justify-content: center;
					height: 48px;
					font-size: 0;
					line-height: 0;
					color: transparent;
				}
			}
		}

		&--have-small-2 {
			#{$block}__submenu-item--small {
				width: 50%;
			}
		}

		&--have-small-3 {
			#{$block}__submenu-item--small {
				width: calc(100% / 3);
			}
		}
	}

	&__submenu-item {
		width: 100%;

		&:not(#{block}__submenu-item--small) {
			border-bottom: 1px solid $borderPrimaryColorOpacity06;
		}

		&:last-child {
			border: none;
		}
	}

	&__submenu-reactions {
		position: absolute;
		top: -50px;
		left: 0;
		max-width: 282px;
		height: 42px;
		padding: 3px;
		background-color: #343434;
		box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
		border-radius: 21px;
		z-index: 1;
	}

	&__submenu-reactions-axis-x {
		width: 100%;
		height: 100%;
		scrollbar-width: none;
		overflow: scroll hidden;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__submenu-reactions-items {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-shrink: 0;
		margin: 0 -2px;
	}

	&__submenu-reactions-btn {
		flex-shrink: 0;
		display: block;
		width: 36px;
		height: 36px;
		margin: 0 2px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-color: $backgroundInverseOpacity10;
		border-radius: 18px;
		cursor: pointer;
		user-select: none;
	}

	&__submenu-btn {
		width: 100%;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: $textPrimary;
		border-bottom: 1px solid $borderPrimaryColorOpacity06;
		transition: $duration background-color;
		padding: 9px 12px;

		&--warn {
			color: $danger;
		}

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:focus,
		&:active {
			background-color: $backgroundInverseOpacity12;
		}

		&:last-child {
			border-bottom: none;
		}

		* {
			pointer-events: none;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}

	&__message-submenu-icon {
		&--reply {
			width: 12px;
			height: 9px;
		}

		&--edit {
			width: 13px;
			height: 13px;
		}
	}

	&__message-avatar-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__avatar-submenu {
		position: absolute;
		width: 228px;
		bottom: 0;
		left: 36px;
		background-color: #38383d;
		border-radius: 12px;
		z-index: 3;
		overflow: hidden;
	}

	&__avatar-submenu-name {
		border-bottom: 1px solid $borderPrimaryColorOpacity06;
		font-size: 13px;
		line-height: 32px;
		font-weight: 600;
		color: $textPrimaryOpacity60;
		padding: 0 12px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__avatar-submenu-icon {
		&--mute {
			path {
				fill: $danger;
			}
		}
	}

	&__messages-preloader {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 64px;
		left: 0;
		width: 100%;
		pointer-events: none;
		z-index: 1;

		&--down {
			top: 110px;
		}

		svg {
			width: 36px;
			height: 36px;
		}
	}

	&__scrollbtns {
		display: flex;
		align-items: flex-start;
		position: absolute;
		bottom: 16px;
		right: 12px;
		z-index: 2;
	}

	&__mention {
		display: flex;
		align-items: center;
		text-align: center;
		margin-top: 2px;
		margin-right: 6px;
		width: 24px;
		height: 24px;
		padding: 0;
		background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.30788 16C9.47098 16 10.5852 15.8465 11.4258 15.5779V14.2446C10.8393 14.5036 9.60782 14.6667 8.3763 14.6667C4.20281 14.6667 1.52474 12.0863 1.52474 8.06715V8.04796C1.52474 4.11511 4.23213 1.32374 8.03421 1.32374C11.8754 1.32374 14.4753 3.65468 14.4753 7.11751V7.13669C14.4753 9.34293 13.7422 10.7722 12.5889 10.7722C11.9243 10.7722 11.5528 10.3789 11.5528 9.70743V4.25899H9.89126V5.41966H9.72511C9.34392 4.57554 8.47404 4.06715 7.44777 4.06715C5.45388 4.06715 4.08552 5.67866 4.08552 8V8.01918C4.08552 10.446 5.44411 12.0767 7.47709 12.0767C8.62065 12.0767 9.47098 11.5492 9.88149 10.5707H10.0476L10.0574 10.6283C10.2431 11.5492 11.113 12.1439 12.2663 12.1439C14.5535 12.1439 16 10.187 16 7.08873V7.06954C16 2.89688 12.7844 0 8.14172 0C3.31338 0 0 3.2518 0 7.99041V8.00959C0 12.8729 3.24496 16 8.30788 16ZM7.78986 10.5803C6.60721 10.5803 5.88393 9.6211 5.88393 8.06715V8.04796C5.88393 6.47482 6.59743 5.53477 7.80941 5.53477C9.03115 5.53477 9.81307 6.51319 9.81307 8.04796V8.06715C9.81307 9.60192 9.03116 10.5803 7.78986 10.5803Z' fill='white'/%3E%3C/svg%3E%0A")
				no-repeat center,
			$primary;
		border-radius: 12px;
		user-select: none;
	}

	&__unreaded-messages {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2;
	}

	&__unreaded-messages-text {
		width: 28px;
		height: 28px;
		background-color: $primary;
		text-align: center;
		border-radius: 50%;
		font-size: 15px;
		line-height: 28px;
		font-weight: 400;
		margin-bottom: 5px;
		align-self: flex-end;
	}

	&__unreaded-messages-btn {
		width: 28px;
		height: 28px;
		background: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.9999' cy='14' r='8.90909' fill='white'/%3E%3Cpath d='M14 -1.02202e-05C6.34269 -9.55075e-06 -1.89453e-06 6.32913 -1.22392e-06 14C-5.54496e-07 21.6573 6.34269 28 14.0136 28C21.6709 28 28 21.6573 28 14C28 6.32913 21.6573 -1.08896e-05 14 -1.02202e-05ZM13.9864 6.88479C14.7183 6.88479 15.2469 7.38624 15.2469 8.10454L15.2469 14.7318L15.1113 17.6321L16.3853 16.06L17.9574 14.4201C18.1607 14.1897 18.4724 14.0542 18.8112 14.0542C19.4753 14.0542 19.9632 14.5285 19.9632 15.1926C19.9632 15.5314 19.8683 15.8025 19.6379 16.0465L14.9758 20.7493C14.6641 21.0745 14.3659 21.2236 13.9864 21.2236C13.5934 21.2236 13.2953 21.0745 12.9835 20.7493L8.33495 16.0465C8.10455 15.8025 7.99613 15.5315 7.99613 15.1926C7.99613 14.5285 8.49758 14.0542 9.16166 14.0542C9.50048 14.0542 9.79864 14.1762 10.0155 14.4201L11.5741 16.0465L12.8616 17.6457L12.726 14.7318L12.726 8.10454C12.726 7.38624 13.2546 6.88479 13.9864 6.88479Z' fill='%23FF3F2A'/%3E%3C/svg%3E%0A")
			no-repeat center;
		font-size: 0;
		line-height: 1;
		color: transparent;
	}

	&__mention,
	&__unreaded-messages-text,
	&__unreaded-messages-btn {
		-webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))
			drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
	}

	&__textarea-container {
		position: relative;
		width: 100%;
	}

	&__textarea-wrap-btn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		height: 100%;
		margin-left: 8px;
	}

	&__textarea-stickers-emoji-btn {
		position: absolute;
		width: 24px;
		height: 24px;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		&--upper {
			top: auto;
			bottom: 28px;
			transform: none;
		}

		* {
			pointer-events: none;
		}
	}

	&__textarea-send-btn,
	&__textarea-timer-btn {
		display: block;
		width: 32px;
		height: 32px;
		flex-shrink: 0;
		margin-left: 8px;
		font-size: 0;
		color: transparent;
		background: $primary;
		border: none;
		border-radius: 16px;

		svg {
			pointer-events: none;

			path {
				transition: fill 0.4s;
			}
		}

		&[disabled] {
			background-color: #2b2a2f;

			svg {
				path {
					fill: rgba(255, 255, 255, 0.4);
				}
			}
		}
	}

	&__textarea-timer-btn {
		color: $textPrimaryOpacity40;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		line-height: 32px;
		letter-spacing: -0.4px;
		font-feature-settings: 'tnum';
		font-variant-numeric: tabular-nums;
		background: #2c2c2e;
	}

	&__intro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		.react-loading-skeleton {
			--base-color: #222227;
			--highlight-color: #525253;
		}

		#{$block}__intro-message-body {
			background-color: #222227;
		}
	}

	&__intro-messages {
		width: 256px;
		margin-bottom: 14px;
	}

	&__intro-message {
		display: flex;
		align-items: flex-end;
		margin-bottom: 8px;

		&:first-child {
			max-width: 197px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__intro-message-avatar {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		border-radius: 16px;

		span {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__intro-message-body {
		width: calc(100% - 40px);
		padding: 8px;
		background-color: #1f2022;
		border-radius: 8px;

		> span {
			br {
				display: none;
			}
		}

		.react-loading-skeleton {
			display: block;
			max-width: 179px;
			height: 8px;
			margin-top: 8px;
			border-radius: 8px;

			&:first-child {
				margin-top: 0;
				width: 49px;
			}
		}
	}

	&__intro-content {
		text-align: center;
		padding-left: 12px;
		padding-right: 12px;
	}

	&__intro-title,
	&__intro-text {
		max-width: 316px;
		font-size: 14px;
		line-height: 24px;
		color: $textPrimaryOpacity30;
	}

	&__intro-title {
		font-weight: 700;
	}

	&__text-length {
		position: absolute;
		right: 13px;
		bottom: 7px;
		color: $textPrimaryOpacity20;
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		transition: color $duration;
	}
}

@keyframes blink {
	0%,
	100% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}
}
