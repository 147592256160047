@import 'assets/styles/imports';

.main-bottom {
	position: relative;
	flex-shrink: 0;
	min-height: 50px;
	background-color: #1a191c;
	z-index: 102;

	&--pt {
		padding-top: 48px;
	}
}
