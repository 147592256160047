@import 'assets/styles/_imports';

.namechanging {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $backgroundPrimary;

	&__header {
		display: flex;
		align-items: center;
		padding: 16px;
		border-bottom: 1px solid $borderPrimaryColorOpacity10;

		svg {
			flex-shrink: 0;
		}
	}

	&__header-container {
		margin-left: 10px;
	}

	&__title {
		font-size: 17px;
		line-height: 22px;
		font-weight: 500;
		color: $textPrimary;
	}

	&__subtitle {
		font-size: 13px;
		line-height: 18px;
		font-weight: 400;
		color: $textPrimaryOpacity40;
	}

	&__body {
		padding: 24px 16px 16px;
		height: 100%;

		.chatname-form {
			height: 100%;
		}
	}
}
