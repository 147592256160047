@import 'assets/styles/imports';

.pinned-message {
	position: fixed;
	top: 64px;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	min-height: 48px;
	padding: 8px 16px;
	background: $backgroundPrimary;
	border-top: 1px solid $borderPrimaryColorOpacity06;
	border-bottom: 1px solid $borderPrimaryColorOpacity06;
	z-index: 4;

	&__container {
		max-width: 80%;
		display: flex;
		align-items: center;
		position: relative;
		font-size: 0;
		line-height: 0;
	}

	&__scrollbtn {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__icon {
		margin-right: 12px;
		flex-shrink: 0;
	}

	&__title {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: $textPrimary;
		margin-bottom: 4px;
	}

	&__text {
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		color: $textPrimaryOpacity60;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__body {
		max-width: 100%;
	}

	&__unpin {
		width: 21px;
		height: 21px;
		font-size: 0;
		line-height: 0;
		margin-left: auto;
		background-size: 12px 12px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.32038 1.10823C1.98565 0.773494 1.44293 0.773494 1.1082 1.10823C0.773463 1.44296 0.773463 1.98568 1.1082 2.32041L4.78782 6.00004L1.1082 9.67966C0.773463 10.0144 0.773463 10.5571 1.1082 10.8918C1.44293 11.2266 1.98565 11.2266 2.32038 10.8918L6 7.21222L9.67963 10.8918C10.0144 11.2266 10.5571 11.2266 10.8918 10.8918C11.2265 10.5571 11.2265 10.0144 10.8918 9.67966L7.21219 6.00004L10.8918 2.32041C11.2265 1.98568 11.2265 1.44296 10.8918 1.10823C10.5571 0.773494 10.0144 0.773494 9.67963 1.10823L6 4.78785L2.32038 1.10823Z' fill='white'/%3E%3C/svg%3E%0A");
	}
}
