@import 'assets/styles/_imports';

.language {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	&__items {
		background: #1b1b1b;
		border-radius: 16px;
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item {
		&:first-child {
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
		}

		&:last-child {
			border-bottom-right-radius: 16px;
			border-bottom-left-radius: 16px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid rgba(84, 84, 88, 0.65);
		}
	}

	&__button {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: space-between;
		width: 100%;
		padding: 10px 43px 10px 16px;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $textPrimaryOpacity80;
		background: none;
		border: none;
		user-select: none;

		&:after {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 16px;
			width: 18px;
			height: 18px;
			background: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.89729 18C7.43567 18 7.85214 17.772 8.14673 17.3264L17.6953 2.15544C17.9086 1.81347 18 1.52332 18 1.24352C18 0.507772 17.4921 0 16.7607 0C16.263 0 15.9582 0.186529 15.6535 0.673575L6.8465 14.9223L2.32619 9.00518C2.02144 8.60104 1.70655 8.43523 1.25959 8.43523C0.528217 8.43523 0 8.96373 0 9.68912C0 10.0104 0.111738 10.3212 0.375847 10.6425L5.6377 17.3368C5.99323 17.7927 6.36907 18 6.89729 18Z' fill='%23FFED00'/%3E%3C/svg%3E%0A")
				no-repeat center;
			transform: translateY(-50%);
			opacity: 0;
			visibility: hidden;
			transition: opacity $duration, visibility $duration;
			z-index: 1;
		}

		&--active {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
