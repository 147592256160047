@import 'assets/styles/_imports';

.users-modal {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;

	&__title {
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: $textPrimary;
		margin-bottom: 24px;

		@include dsk-small {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__user-container {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__user-avatar {
		width: 36px;
		height: 36px;
		margin-right: 12px;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;

		@include tablet {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__user-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__filter {
		display: flex;
		width: 100%;
		padding: 0 12px;
		margin-bottom: 8px;
	}

	&__filter-item {
		margin-right: 24px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__filter-btn {
		display: flex;
		align-items: center;
		padding: 0 0 4px;
		color: #919193;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		border-bottom: 2px solid transparent;
		transition: color $duration, border $duration;
		user-select: none;
		cursor: pointer;

		@include dsk-small {
			font-size: 16px;
			line-height: 20px;
		}

		@include tablet {
			font-size: 16px;
			line-height: 20px;
		}

		&.active {
			color: $textPrimary;
			border-bottom-color: $primary;
		}
	}

	&__body {
		flex-grow: 1;
		max-height: calc(100% - 48px);
	}

	&__axis-y {
		width: 100%;
		height: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__users-list {
		height: 100%;
	}

	&__user {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 12px;
	}

	&__user--active &__user-controls {
		display: block;
	}

	&__user--active &__user-name {
		border-bottom-color: transparent;
	}

	&__user-container {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		background-color: transparent;
		border-radius: 8px;
	}

	&__user-avatar {
		width: 28px;
		height: 28px;
		margin-right: 12px;
	}

	&__user-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	&__user-wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 40px);
	}

	&__user-name {
		display: flex;
		align-items: center;
		width: calc(100% - 32px);
		padding: 14px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.06);
		transition: border $duration;
	}

	&__user-name-item {
		display: block;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $textPrimary;
		white-space: nowrap;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&__user-admin {
		display: flex;
		align-items: center;
		position: relative;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $textPrimaryOpacity60;
		margin-left: 3px;

		&::before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			position: relative;
			margin-right: 3px;
			margin-bottom: 1px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.77042 14.1256H5.26774C5.40068 14.1256 5.49863 14.1606 5.59659 14.2586L6.66011 15.3151C7.56969 16.2318 8.42331 16.2248 9.3329 15.3151L10.3964 14.2586C10.5014 14.1606 10.5923 14.1256 10.7323 14.1256H12.2296C13.51 14.1256 14.1187 13.5169 14.1187 12.2294V10.732C14.1187 10.599 14.1607 10.5011 14.2587 10.4031L15.3152 9.33953C16.2318 8.4299 16.2248 7.57624 15.3152 6.6666L14.2587 5.60302C14.1537 5.49806 14.1187 5.4071 14.1187 5.27415V3.77675C14.1187 2.48927 13.517 1.88051 12.2296 1.88051H10.7323C10.5923 1.88051 10.4944 1.84552 10.3964 1.74756L9.3329 0.683987C8.42331 -0.232647 7.56969 -0.22565 6.66011 0.690984L5.59659 1.74756C5.49863 1.83853 5.40068 1.88051 5.26774 1.88051H3.77042C2.476 1.88051 1.87428 2.47527 1.87428 3.77675V5.27415C1.87428 5.4071 1.83929 5.50506 1.74134 5.60302L0.684817 6.6666C-0.231767 7.57624 -0.224771 8.4299 0.684817 9.33953L1.74134 10.4031C1.83929 10.5011 1.87428 10.599 1.87428 10.732V12.2294C1.87428 13.5169 2.483 14.1256 3.77042 14.1256ZM7.21985 11.4527C6.95397 11.4527 6.74407 11.3407 6.54116 11.0888L4.82694 9.00367C4.701 8.84273 4.63802 8.68179 4.63802 8.49987C4.63802 8.14301 4.92489 7.85612 5.28173 7.85612C5.49863 7.85612 5.66656 7.93309 5.84848 8.164L7.19886 9.87132L10.0886 5.24617C10.2425 5.00126 10.4384 4.87531 10.6553 4.87531C11.0051 4.87531 11.327 5.12022 11.327 5.49107C11.327 5.652 11.243 5.82693 11.1451 5.98087L7.86356 11.0818C7.70963 11.3267 7.48573 11.4527 7.21985 11.4527Z' fill='%230D6EFF'/%3E%3C/svg%3E%0A");
			background-size: cover;
			top: 0;
		}
	}

	&__user-icon {
		display: block;
		flex-shrink: 0;
		width: 28px;
		height: 28px;
		margin-left: 4px;
		font-size: 0;
		line-height: 0;
		background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.52698 0.919498C8.84783 0.582376 9.36803 0.582376 9.68888 0.919499L9.75936 0.993554C10.0802 1.33068 10.0802 1.87726 9.75936 2.21438L6.1619 5.99431C5.5202 6.66855 4.4798 6.66855 3.8381 5.99431L0.240638 2.21438C-0.0802125 1.87726 -0.0802125 1.33068 0.240637 0.993554L0.311118 0.919499C0.631968 0.582376 1.15217 0.582376 1.47302 0.919499L4.41905 4.01495C4.7399 4.35207 5.2601 4.35207 5.58095 4.01495L8.52698 0.919498Z' fill='white'/%3E%3C/svg%3E%0A")
			no-repeat center;

		&--active {
			transform: scaleY(-1);
		}
	}

	&__user-btn {
		position: absolute;
		top: 4px;
		bottom: 4px;
		left: -12px;
		right: -12px;
		font-size: 0;
		line-height: 0;
		background: none;
		border-radius: 8px;
		transition: background $duration;
		z-index: 1;

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:active {
			background-color: $backgroundInverseOpacity12;
		}
	}

	&__user-controls {
		display: none;
		width: 100%;
		padding-left: 21px;
	}

	&__user-controls-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 8px 20px 8px 19px;
		background: none;
		border-radius: 8px;
		transition: background $duration;

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:active {
			background-color: $backgroundInverseOpacity12;
		}

		svg {
			width: 20px;
			height: 20px;

			path {
				fill: #fff;
			}

			circle {
				fill: none;
			}
		}
	}

	&__user-controls-action {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
	}

	&__user-controls-icon {
		width: 18px;
		height: 18px;

		path {
			fill: #fff;
		}
	}

	&__user-controls-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $textPrimary;
		margin-bottom: 4px;
	}

	&__user-controls-subtitle {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: $textPrimaryOpacity40;
	}
}
