@import 'assets/styles/_imports';

.settings {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__groups {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__group {
		margin-bottom: 22px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__group-title {
		margin-bottom: 8px;
		padding-left: 16px;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
	}

	&__item {
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}

		&--switcher {
			display: flex;
			align-items: center;
			position: relative;
			justify-content: space-between;
			width: 100%;
			padding: 10px 16px;
			background: rgba(255, 255, 255, 0.06);
			border-radius: 12px;

			& + .settings__item .settings__button {
				padding-top: 13px;
				padding-bottom: 13px;
			}
		}
	}

	&__item-title {
		margin-right: 12px;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: white;
	}

	&__item-switcher {
		flex-shrink: 0;
		margin-left: auto;
	}

	&__button {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: space-between;
		width: 100%;
		padding: 13px 40px 11px 12px;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
		background: rgba(255, 255, 255, 0.06);
		border: none;
		border-radius: 12px;
		transition: background $duration;
		user-select: none;

		&:hover {
			background: $backgroundInverseOpacity12;
		}

		&:active {
			background: $backgroundInverseOpacity20;
		}

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:after {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 12px;
			width: 20px;
			height: 20px;
			background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.74408 6.49408C7.41864 6.16864 7.41864 5.641 7.74408 5.31557L7.81557 5.24408C8.141 4.91864 8.66864 4.91864 8.99408 5.24408L12.643 8.89298C13.2938 9.54385 13.2939 10.5991 12.643 11.25L8.99408 14.8989C8.66864 15.2243 8.141 15.2243 7.81557 14.8989L7.74408 14.8274C7.41864 14.502 7.41864 13.9743 7.74408 13.6489L10.7322 10.6607C11.0577 10.3353 11.0577 9.80767 10.7322 9.48223L7.74408 6.49408Z' fill='white'/%3E%3C/svg%3E%0A")
				no-repeat center;
			transform: translateY(-50%);
			z-index: 1;
		}

		span {
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: $textPrimaryOpacity60;
		}
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: auto;
		padding-bottom: 20px;
	}

	&__delete-account {
		padding: 5px;
		font-size: 14px;
		line-height: 20px;
		color: $textPrimary;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__powered-by {
		font-size: 13px;
		line-height: 14px;
		color: $textPrimaryOpacity30;
	}

	&__text {
		margin-top: 22px;
		padding-left: 16px;
		padding-right: 16px;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.078px;
		color: rgba(255, 255, 255, 0.4);
	}
}
