@import 'assets/styles/_imports';

.toasts {
	position: absolute;
	left: 12px;
	bottom: 67px;
	width: calc(100vw - 32px);
	z-index: 20000;
}

.toast {
	$block: '.toast';

	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 6px;
	padding: 10px 12px 10px 12px;
	background: $backgroundSecond;
	border-radius: 8px;

	&::after {
		content: '';
		position: absolute;
		top: 10px;
		left: 0;
		width: 2px;
		height: 24px;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		background-color: $primary;
	}

	&__close-btn {
		width: 24px;
		height: 24px;
		background-color: $backgroundInverseOpacity06;
		border-radius: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__overico {
		flex-shrink: 0;
		width: 20px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin-right: 8px;
		align-self: flex-start;

		path {
			fill: $primary;
		}
	}

	&__text {
		width: calc(100% - 64px);
		font-size: 16px;
		line-height: 20px;
		font-weight: normal;
		color: $textPrimary;
		white-space: break-spaces;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__close-btn {
		flex-shrink: 0;
		margin-left: 8px;

		svg {
			pointer-events: none;
		}
	}

	&--have-img {
		#{$block}__overico {
			border-radius: 50%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
}
