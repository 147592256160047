@import 'assets/styles/_imports';

$defaultBackgroundColor: #0d6eff;
$correctBackgroundColor: #1b7e49;
$notCorrectBackgroundColor: #6b1a2b;

@mixin defaultMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11' r='11' fill='white'/%3E%3Cpath d='M10.3318 16C10.6309 16 10.8623 15.8733 11.026 15.6258L16.3307 7.19747C16.4492 7.00748 16.5 6.84629 16.5 6.69085C16.5 6.2821 16.2178 6 15.8115 6C15.535 6 15.3657 6.10363 15.1964 6.37421L10.3036 14.2902L7.79233 11.0029C7.62302 10.7784 7.44808 10.6862 7.19977 10.6862C6.79345 10.6862 6.5 10.9799 6.5 11.3828C6.5 11.5613 6.56208 11.734 6.7088 11.9125L9.63205 15.6315C9.82957 15.8849 10.0384 16 10.3318 16Z' fill='black'/%3E%3C/svg%3E%0A");
}

@mixin correctMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11' r='11' fill='%233DBB3D'/%3E%3Cpath d='M10.3318 16C10.6309 16 10.8623 15.8733 11.026 15.6258L16.3307 7.19747C16.4492 7.00748 16.5 6.84629 16.5 6.69085C16.5 6.2821 16.2178 6 15.8115 6C15.535 6 15.3657 6.10363 15.1964 6.37421L10.3036 14.2902L7.79233 11.0029C7.62302 10.7784 7.44808 10.6862 7.19977 10.6862C6.79345 10.6862 6.5 10.9799 6.5 11.3828C6.5 11.5613 6.56208 11.734 6.7088 11.9125L9.63205 15.6315C9.82957 15.8849 10.0384 16 10.3318 16Z' fill='white'/%3E%3C/svg%3E%0A");
}

@mixin notCorrectMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11' r='11' fill='white'/%3E%3Cpath d='M6.5 6L11.5 11M16.5 16L11.5 11M11.5 11L16.5 6L6.5 16' stroke='%23E50914' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.poll-message {
	$block: '.poll-message';

	display: flex;
	justify-content: flex-end;
	width: 100%;

	&--quiz#{&}--correct {
		#{$block}__item-content {
			&:before {
				@include correctMarker;
			}
		}
	}

	&--quiz#{&}--not-correct {
		#{$block}__item-content {
			&:before {
				@include notCorrectMarker;
			}
		}
	}

	&--quiz-multiple {
		#{$block}__item {
			&--correct {
				#{$block}__item-content {
					&:before {
						@include correctMarker;
					}
				}
			}

			&--not-correct {
				#{$block}__item-content {
					&:before {
						@include notCorrectMarker;
					}
				}
			}
		}
	}

	&__container {
		max-width: 310px;
		min-width: 230px;
		padding: 11px 12px;
		background-color: $primary;
		border-radius: 12px;
	}

	&__title {
		margin-bottom: 2px;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: $textPrimaryOpacity70;
	}

	&__question {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $textPrimary;
		word-break: break-word;
	}

	&__items {
		margin-top: 12px;
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item-img {
		width: 42px;
		height: 42px;
		margin-right: 6px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 8px;

		& + #{$block}__item-content {
			width: calc(100% - 48px);
		}
	}

	&__item-content {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		min-height: 42px;
		padding-right: 40px;

		&:before {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 0px;
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-position: center;
			@include defaultMarker;
			transform: translateY(-50%);
			z-index: 1;
		}
	}

	&__item-title {
		display: block;
		max-width: 208px;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimary;

		& + #{block}__item-voting {
			margin-top: 4px;
		}
	}

	&__item-voting {
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__item-voting-percent {
		margin-right: 11px;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textPrimary;
	}

	&__item-voting-indicate {
		width: 88px;
		height: 5px;

		span {
			display: block;
			height: 100%;
			background-color: $backgroundInverse;
			border-radius: 100px;
		}
	}
}
