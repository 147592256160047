@import '../imports';

.modal {
	$block: '.modal';

	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 16px 12px 12px;
	background-color: $backgroundPrimary;
	border-radius: 16px 16px 0px 0px;
	overflow: hidden;

	&--autoheight {
		height: auto;
		max-height: 100%;
	}

	&--poll-edit {
		#{$block}__axis-y {
			padding-bottom: 24px;
		}
	}

	&--ban,
	&--feedback-audio,
	&--chat-bot-rules {
		padding: 16px;
		background: rgba(22, 21, 26, 0.7);
		border-radius: 0px;
	}

	&--record {
		height: auto;
		min-height: 89px;
	}

	&--record-min {
		padding: 0 0 16px;
		border-radius: 0;

		#{$block}__toggle-view {
			top: 28px;

			&:before {
				transform: translateY(-50%) rotate(270deg);
				transform-origin: 8px 12px;
			}
		}

		.recording {
			padding-top: 0;

			&__head {
				display: none;
			}
		}

		.player {
			&__body {
				padding-top: 0;
				padding-left: 16px;
				padding-right: 16px;
			}

			&__progressbar-range::-webkit-slider-thumb,
			&__progressbar-times {
				display: none;
			}
		}
	}

	&__head {
		position: relative;
		width: 100%;
		min-height: 24px;
		padding: 0 28px;
	}

	&__head-overico {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 48px;
		height: 48px;
		margin-right: 10px;
		border-radius: 50%;
		background-color: $backgroundInverseOpacity20;
		overflow: hidden;
	}

	&__head-rigth {
		width: calc(100% - 100px);

		#{$block}__title {
			text-align: left;
			font-weight: 400;
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__head + .profile-screens {
		margin-top: -24px;
	}

	&__title {
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: $textPrimary;

		@include dsk-small {
			font-size: 16px;
			line-height: 20px;
		}

		@include tablet {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__subtitle {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity40;
	}

	&__remove-btn,
	&__close {
		display: block;
		position: absolute;
		width: 24px;
		height: 24px;
		position: absolute;
		top: -4px;
		right: 12px;
		font-size: 0;
		line-height: 0;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		color: transparent;
		user-select: none;
		z-index: 1;
	}

	&__back,
	&__prev {
		width: 24px;
		height: 24px;
		position: absolute;
		top: -4px;
		font-size: 0;
		line-height: 0;
		background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.70711 1.79289C7.09763 1.40237 7.09763 0.769204 6.70711 0.37868L6.62132 0.292893C6.2308 -0.0976308 5.59763 -0.0976313 5.20711 0.292893L0.828427 4.67157C0.047379 5.45262 0.0473781 6.71895 0.828427 7.5L5.20711 11.8787C5.59763 12.2692 6.2308 12.2692 6.62132 11.8787L6.70711 11.7929C7.09763 11.4024 7.09763 10.7692 6.70711 10.3787L3.12132 6.79289C2.73079 6.40237 2.7308 5.7692 3.12132 5.37868L6.70711 1.79289Z' fill='white'/%3E%3C/svg%3E%0A");
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
		user-select: none;
		z-index: 1;
	}

	&__back {
		left: 6px;
	}

	&__prev {
		left: 0;
	}

	&__close {
		right: 0;
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='%23E50914' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-size: 16px;
	}

	&__remove-btn {
		left: 0;
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.34404 0.333008C3.06175 0.333008 2.81006 0.510801 2.71571 0.776857L2.4793 1.44352C2.4529 1.51796 2.4405 1.59304 2.4404 1.66634H1.00065C0.632461 1.66634 0.333984 1.96482 0.333984 2.33301C0.333984 2.7012 0.632461 2.99967 1.00065 2.99967H9.00065C9.36884 2.99967 9.66732 2.7012 9.66732 2.33301C9.66732 1.96482 9.36884 1.66634 9.00065 1.66634H7.56125C7.56115 1.59304 7.54875 1.51796 7.52235 1.44352L7.28594 0.776857C7.19159 0.5108 6.9399 0.333008 6.65761 0.333008H3.34404Z' fill='%23E50914'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.70864 4.33301C0.888184 4.33301 0.262251 5.06612 0.391936 5.87627L0.891921 8.99967C1.13118 10.4943 1.64846 11.989 1.9894 12.8704C2.17863 13.3596 2.65374 13.6663 3.17824 13.6663H6.82287C7.34737 13.6663 7.82249 13.3596 8.01172 12.8704C8.35265 11.989 8.86993 10.4943 9.1092 8.99967L9.60918 5.87627C9.73886 5.06612 9.11293 4.33301 8.29248 4.33301H1.70864ZM8.29248 5.66634H1.70864L2.20849 8.78892C2.42126 10.1181 2.88417 11.4806 3.21123 12.333H6.78988C7.11695 11.4806 7.57985 10.1181 7.79262 8.78892L8.29248 5.66634Z' fill='%23E50914'/%3E%3C/svg%3E%0A");
		background-size: 14px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;

		&--no-overflow {
			overflow: visible;
		}

		&:not(:first-child) {
			margin-top: 16px;
		}
	}

	&__axis-y {
		flex-grow: 1;
		width: 100%;
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__rules-text {
		font-size: 15px;
		line-height: 20px;
		padding-left: 7px;

		a {
			font-size: 15px;
			border-bottom: 1px solid $primary;
			transition: $duration border-color;
			color: $primary;
			font-size: 11pt;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h1 {
			color: #fff;
			line-height: normal;
			font-weight: bold;
		}

		h1 {
			font-size: 23px;
		}

		h2 {
			font-size: 21px;
			margin: 19px 0;
		}

		h3 {
			font-size: 18px;
			margin: 19px 0 10px 0;

			& + p {
				margin-top: 11px;
			}
		}

		h4 {
			font-size: 16px;
			margin: 19px 0 7px 0;
		}

		.p,
		p {
			font-weight: normal;
			font-size: 16px;
			margin: 0 0 9px;
			line-height: 131%;
			text-align: left;
		}

		.a,
		a {
			font-size: 16px;
		}

		.s1 {
			font-size: 16px;
		}

		ul {
			list-style: disc;
			padding-left: 27px;
			margin: 18px 0;

			li {
				font-size: 16px;
			}
		}

		strong {
			font-weight: 700;
			color: #fff;
		}
	}

	&__toggle-view {
		display: flex;
		flex-direction: column;
		width: 32px;
		height: 32px;
		position: absolute;
		top: 16px;
		right: 16px;
		z-index: 2;

		&:before {
			content: '';
			display: block;
			width: 9px;
			height: 16px;
			margin: auto;
			background: url('data:image/svg+xml,%3Csvg width=%279%27 height=%2716%27 viewBox=%270 0 9 16%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1 1L8 8L1 15%27 stroke=%27white%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A')
				no-repeat center;
			transform: translateY(-50%) rotate(90deg);
			transform-origin: 0 12px;
			z-index: 1;
		}
	}

	&--variant-two {
		padding: 0;

		#{$block}__head {
			display: flex;
			align-items: center;
			padding: 16px 60px 16px 16px;
			border-bottom: 1px solid $borderPrimaryColorOpacity20;
		}

		#{$block}__body {
			padding: 16px;
		}

		#{$block}__close {
			top: 16px;
			right: 16px;
		}
	}
}

.ReactModal {
	&__Overlay {
		&--min {
			position: fixed;
			width: 100%;
			height: auto;
			inset: unset;
			left: 0;
			bottom: 0;
		}
	}

	&__Body {
		&--app-ban {
			.app-close-button {
				z-index: 10001;
			}
		}
	}
}
