@import 'assets/styles/imports';

.link-preview-writing {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 48px;
	padding: 5px 22px 5px 8px;
	background-color: #222227;
	border-bottom: 1px solid $borderPrimaryColorOpacity20;
	z-index: 2;

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 37px;
		height: 30px;
		margin-right: 11px;
		border-right: 1px solid $primary;

		svg {
			path {
				fill: $primary;
			}
		}
	}

	&__content {
		width: calc(100% - 87px);
	}

	&__title,
	&__text {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__title {
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $primary;
		margin-bottom: 2px;
	}

	&__text {
		font-size: 12px;
		line-height: 14px;
		color: $textPrimary;
	}

	&__close {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-left: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
