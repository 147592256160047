@import 'assets/styles/_imports';

.report-modal {
	width: 100%;
	height: 100%;
	background-color: $backgroundPrimary;
	position: relative;
	border-radius: 16px 16px 0px 0px;
	display: flex;
	flex-direction: column;

	&__close {
		position: absolute;
		width: 24px;
		height: 24px;
		font-size: 0;
		line-height: 0;
		top: 15px;
		right: 16px;
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='%23EB4D3D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
			no-repeat center;
		background-size: contain;
	}

	&__header {
		display: flex;
		align-items: center;
		padding: 16px;
		border-bottom: 1px solid $borderPrimaryColorOpacity20;
	}

	&__header-icon {
		width: 48px;
		height: 48px;
	}

	&__header-texts {
		margin-left: 10px;
	}

	&__title {
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $textPrimary;
	}

	&__subtitle {
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		font-weight: 500;
		color: $textPrimaryOpacity40;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-grow: 1;
		padding: 18px 16px 32px;
		overflow-y: auto;
	}

	&__axis-y {
		width: 100%;
		height: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__btns-list {
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}

	&__btn {
		display: block;
		text-align: left;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $danger;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
