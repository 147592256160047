@import 'assets/styles/_imports';

.poll-settings {
	&__title {
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.3px;
		color: $textPrimaryOpacity60;
		margin-bottom: 8px;
	}

	&__group {
		margin-bottom: 16px;
		background: $backgroundInverseOpacity10;
		border-radius: 16px;
		overflow: hidden;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 48px;
		margin-left: 15px;
		margin-right: 15px;
		font-size: 14px;
		line-height: 20px;
		border-bottom: 1px solid $borderPrimaryColorOpacity10;

		&:last-child {
			border-color: transparent;
		}
	}
}
