@import 'assets/styles/_imports';

.error-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $backgroundPrimary;
	z-index: 9000000000;

	.error {
		&__container {
			&:first-child {
				margin-left: 0;
				padding-bottom: 0;
			}
		}

		&__text {
			word-break: break-word;
		}
	}
}

.error {
	display: flex;
	flex-direction: column;
	background-color: $backgroundPrimary;
	padding: 16px 20px 32px;
	height: 100%;

	&__header {
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 16px;

		@include dsk-small {
			font-size: 14px;
			line-height: 16px;
		}

		@include tablet {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__title {
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		margin-bottom: 8px;

		@include dsk-small {
			font-size: 12px;
			line-height: 14px;
		}

		@include tablet {
			font-size: 12px;
			line-height: 14px;
		}
	}

	&__subtitle {
		color: $danger;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;

		@include dsk-small {
			font-size: 10px;
			line-height: 12px;
		}

		@include tablet {
			font-size: 10px;
			line-height: 12px;
		}
	}

	&__text {
		margin-top: 24px;
		font-size: 14px;
		line-height: 16px;
	}
}
