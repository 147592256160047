@import 'assets/styles/_imports';

.blocked-users {
	$block: '.blocked-users';

	&--empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-left: 16px;
		padding-right: 16px;
		flex-grow: 1;
	}

	&__text {
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
	}

	&__item {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		user-select: none;
		z-index: 1;
		padding: 0 12px;
		border-radius: 8px;
		overflow: hidden;
		transition: $duration background-color;

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:active,
		&:focus {
			background-color: $backgroundInverseOpacity12;
		}

		&--accent {
			#{$block}__item-btn {
				color: $danger;
				background: rgba(229, 9, 20, 0.2);
			}
		}
	}

	&__item-avatar {
		flex-shrink: 0;
		width: 28px;
		height: 28px;
		border-radius: 14px;
	}

	&__item-avatar {
		flex-shrink: 0;
		width: 36px;
		height: 36px;
		margin-right: 12px;
		border-radius: 18px;
		overflow: hidden;
	}

	&__item-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__item-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;
		width: calc(100% - 40px);
		overflow: hidden;
	}

	&__item-name {
		display: block;
		width: calc(100% - 32px);
		padding: 18px 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $textPrimary;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: 1px solid rgba(255, 255, 255, 0.06);
		overflow: hidden;
	}

	&__item-btn {
		display: block;
		flex-shrink: 0;
		margin-left: 12px;
		padding: 4px 8px;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: $textPrimaryOpacity80;
		background: $backgroundInverseOpacity06;
		border-radius: 4px;
		border: none;
		cursor: pointer;
		user-select: none;
		transition: color $duration, background $duration;
	}
}
