@import 'assets/styles/_imports';

.waiting-speakers {
	padding: 0 12px;

	&__title {
		margin-bottom: 4px;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #fff;

		@include dsk-small {
			font-size: 14px;
			line-height: 16px;
		}

		@include tablet {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__text {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #59595d;

		@include dsk-small {
			font-size: 12px;
			line-height: 14px;
		}

		@include tablet {
			font-size: 12px;
			line-height: 14px;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__item-avatar,
	&__item-btn {
		flex-shrink: 0;
		width: 28px;
		height: 28px;
		border-radius: 14px;
	}

	&__item-avatar {
		margin-right: 12px;
		overflow: hidden;
	}

	&__item-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__item-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;
		width: calc(100% - 40px);
	}

	&__item-name {
		display: block;
		width: 100%;
		padding: 18px 0;
		margin-right: 14px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: 1px solid rgba(255, 255, 255, 0.06);
		overflow: hidden;
	}

	&__item-btns {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
	}

	&__item-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		background: none;
		border-radius: 6px;
		transition: background $duration;
		user-select: none;

		&:hover,
		&:active {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		&:hover {
			background-color: $backgroundInverseOpacity06;
		}

		&:active {
			background-color: $backgroundInverseOpacity12;
		}

		& + & {
			margin-left: 4px;
		}

		svg {
			width: 18px;
			height: 18px;
			pointer-events: none;

			path {
				fill: #59595d;
				transition: fill $duration;
			}
		}
	}
}
