@import 'assets/styles/_imports';

.poll-options {
	$block: '.poll-options';

	width: 100%;

	&--view-edit {
		#{$block}__item {
			&--no-pic {
				#{$block}__item-title {
					margin-bottom: 5px;
				}
			}
		}

		#{$block}__item-content {
			padding-right: 16px;
		}

		#{$block}__item-img {
			width: 50px;

			& + #{$block}__item-content {
				width: calc(100% - 50px);
			}
		}

		.poll-option-voting {
			&__number {
				color: $textPrimaryOpacity80;
			}
		}
	}

	&--view-vertical {
		display: flex;

		#{$block}__item {
			height: auto;
			margin-bottom: 0;
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}
		}

		#{$block}__item-container {
			flex-direction: column;
		}

		#{$block}__item-img {
			width: 100%;
			height: auto;

			&:after {
				content: '';
				display: block;
				width: 100%;
				padding-top: 100%;
			}
		}

		#{$block}__item-content {
			width: 100% !important;
		}

		#{$block}__item-title {
			text-align: center;
			word-wrap: break-word;
			white-space: unset;
			text-overflow: unset;
		}

		#{$block}__item-ico {
			justify-content: center;
			align-items: flex-start;
			padding-right: 0;
			padding-top: calc(100% + 9px);
		}

		&[data-items='2'] {
			#{$block}__item-content {
				padding: 36px 8px 12px;
			}
		}

		&[data-items='3'] {
			#{$block}__item-content {
				padding: 36px 8px 8px;
			}

			#{$block}__item-title {
				font-size: 13px;
				line-height: 17px;
			}
		}

		.poll-option-voting {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			&__number {
				text-align: center;
			}

			&__indicate {
				display: none;
			}
		}
	}

	&--view-edit#{&}--view-vertical {
		&[data-items='2'] {
			#{$block}__item-content {
				padding-top: 14px;
			}
		}

		&[data-items='3'] {
			#{$block}__item-content {
				padding-top: 14px;
			}
		}
	}

	&:not(.poll-options--view-vertical) {
		#{$block}__item-title {
			white-space: unset;
			text-overflow: unset;
			overflow: visible;
		}
	}

	&--blocked {
		#{$block}__item {
			pointer-events: none;
		}

		#{$block}__item-ico {
			opacity: 0.5;
		}
	}

	&--already-voted {
		#{$block}__item {
			background-color: #262626;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		min-height: 50px;
		margin-bottom: 8px;
		background: #525252;
		box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
		transition: background $duration;
		overflow: hidden;
		user-select: none;

		&:last-child {
			margin-bottom: 0;
		}

		&--correct,
		&--not-correct {
			#{$block}__item-label {
				display: none;
			}
		}

		&--selected {
			#{$block}__item-ico {
				&:after {
					background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.83183 10C4.13093 10 4.3623 9.87334 4.52596 9.62579L9.8307 1.19747C9.94921 1.00748 10 0.846287 10 0.690846C10 0.282096 9.71783 0 9.31151 0C9.03499 0 8.86569 0.103627 8.69639 0.374208L3.80361 8.29016L1.29233 5.00288C1.12302 4.77835 0.948081 4.68624 0.699774 4.68624C0.293454 4.68624 0 4.97985 0 5.38284C0 5.56131 0.0620768 5.73402 0.208804 5.91249L3.13205 9.63155C3.32957 9.88486 3.53837 10 3.83183 10Z' fill='black'/%3E%3C/svg%3E%0A");
					background-color: $backgroundInverse;
					border-color: $borderPrimaryColor;
				}
			}
		}

		&--correct {
			#{$block}__item-ico {
				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.83183 10C4.13093 10 4.3623 9.87334 4.52596 9.62579L9.8307 1.19747C9.94921 1.00748 10 0.846287 10 0.690846C10 0.282096 9.71783 0 9.31151 0C9.03499 0 8.86569 0.103627 8.69639 0.374208L3.80361 8.29016L1.29233 5.00288C1.12302 4.77835 0.948081 4.68624 0.699774 4.68624C0.293454 4.68624 0 4.97985 0 5.38284C0 5.56131 0.0620768 5.73402 0.208804 5.91249L3.13205 9.63155C3.32957 9.88486 3.53837 10 3.83183 10Z' fill='white'/%3E%3C/svg%3E%0A");
					background-color: #e50914;
					border: none;
				}
			}
		}

		&--not-correct {
			#{$block}__item-ico {
				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33398 1.33398L6.00065 6.00065M10.6673 10.6673L6.00065 6.00065M6.00065 6.00065L10.6673 1.33398L1.33398 10.6673' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-color: #e50914;
					border: none;
				}
			}
		}

		&--correct#{&}--selected {
			background-color: #3dbb3d;

			#{$block}__item-ico {
				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.83183 10C4.13093 10 4.3623 9.87334 4.52596 9.62579L9.8307 1.19747C9.94921 1.00748 10 0.846287 10 0.690846C10 0.282096 9.71783 0 9.31151 0C9.03499 0 8.86569 0.103627 8.69639 0.374208L3.80361 8.29016L1.29233 5.00288C1.12302 4.77835 0.948081 4.68624 0.699774 4.68624C0.293454 4.68624 0 4.97985 0 5.38284C0 5.56131 0.0620768 5.73402 0.208804 5.91249L3.13205 9.63155C3.32957 9.88486 3.53837 10 3.83183 10Z' fill='black'/%3E%3C/svg%3E%0A");
					background-color: $backgroundInverse;
				}
			}
		}

		&--not-correct#{&}--selected {
			background-color: #e50914;

			#{$block}__item-ico {
				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33398 1.33398L6.00065 6.00065M10.6673 10.6673L6.00065 6.00065M6.00065 6.00065L10.6673 1.33398L1.33398 10.6673' stroke='%23E50914' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-color: $backgroundInverse;
				}
			}
		}
	}

	&__item-container {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 50px;
	}

	&__item-img {
		width: 50px;
		height: 100%;
		min-height: 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		& + #{$block}__item-content {
			width: calc(100% - 50px);
		}
	}

	&__item-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 4px 58px 4px 16px;
	}

	&__item-title {
		margin-bottom: 2px;
		color: $textPrimary;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item-ico {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-right: 18px;
		pointer-events: none;

		&:after {
			content: '';
			display: block;
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-position: center;
			border: 2px solid #c7c7cc;
			border-radius: 11px;
			transition: background $duration, border $duration;
			z-index: 1;
		}
	}

	&__item-label {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity $duration;
		cursor: pointer;
		user-select: none;
		overflow: hidden;
		z-index: 2;
	}

	&__item-label-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -1;
	}
}
