@import 'assets/styles/_imports';

.profile-main {
	display: flex;
	flex-direction: column;

	&__avatar {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 19px;
	}

	&__avatar-image {
		width: 66px;
		height: 66px;
		margin-bottom: 8px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		overflow: hidden;
	}

	&__avatar-change {
		padding: 5px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $primary;
	}

	&__field {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0 12px;

		@include dsk-small {
			padding: 0 4px;
		}

		@include tablet {
			padding: 0;
		}
	}

	&__field-label {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #919193;
		margin-bottom: 2px;
	}

	&__field-text {
		display: block;
		width: 100%;
		height: 38px;
		padding: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 36px;
		border: none;
		border-bottom: 2px solid #38383d;
		border-radius: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__field-edit {
		margin-top: auto;
	}
}
