@import 'assets/styles/_imports';

.avatar-form {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: calc(100% - 37px);

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 120px;
		background: linear-gradient(180deg, rgba(30, 29, 35, 0) 0%, #1e1d23 77.08%);
		z-index: 1;
	}

	&__picked {
		width: calc(100% / 6 - 8px);
		border-radius: 50%;
		margin-bottom: 12px;
		overflow: hidden;
	}

	&__picked-img {
		width: 100%;
		padding-top: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&__label {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
		margin-bottom: 12px;

		@include dsk-small {
			font-size: 14px;
			line-height: 16px;
		}

		@include tablet {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 8px);
		padding-left: 2px;

		@include dsk-small {
			width: calc(100% + 7px);
		}

		@include tablet {
			width: calc(100% + 6px);
		}
	}

	&__list-spacer {
		margin-top: 100%;
	}

	&__list-item {
		display: inline-block;
		position: relative;
		margin-right: 8px;
		margin-bottom: 8px;
		width: calc(100% / 6 - 8px);

		@include dsk-small {
			margin-right: 7px;
			margin-bottom: 7px;
			width: calc(100% / 6 - 7px);
		}

		@include tablet {
			margin-right: 6px;
			margin-bottom: 6px;
			width: calc(100% / 6 - 6px);
		}
	}

	&__item {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		user-select: none;
		cursor: pointer;

		&:before,
		&:after {
			@include ps-elem;
			position: absolute;
			border-radius: 50%;
			opacity: 0;
			visibility: hidden;
			transition: opacity $duration, visibility $duration;
			pointer-events: none;
		}

		&:before {
			top: -2px;
			left: -2px;
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			border: 1px solid #ff3f2a;
			z-index: 2;
		}

		&:after {
			top: -3px;
			left: -3px;
			width: calc(100% + 3px);
			height: calc(100% + 3px);
			border: 5px solid #1e1d23;
			z-index: 1;
		}

		&:hover,
		&:active {
			&:before,
			&:after {
				opacity: 1;
				visibility: visible;
			}

			.avatar-form__item-img {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	&__item-input {
		display: none;
		overflow: hidden;
		opacity: 0;
	}

	&__item-input:checked ~ &__item-checkmark {
		opacity: 1;
		visibility: visible;
	}

	&__item-img {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='white' fill-opacity='0.2'/%3E%3Ccircle cx='22' cy='22' r='12' fill='white' fill-opacity='0.2'/%3E%3C/svg%3E%0A");
			background-size: cover;
			opacity: 0;
			visibility: hidden;
			transition: opacity $duration, visibility $duration;
			z-index: 1;
		}
	}

	&__item-checkmark {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 31%;
		height: 31%;
		background-color: $primary;
		border: 2px solid $backgroundPrimary;
		border-radius: 50%;
		opacity: 0;
		visibility: hidden;
		transition: opacity $duration, visibility $duration;
		z-index: 3;
	}

	&__btn {
		margin-top: auto;
		z-index: 1;
	}

	&__axis-y {
		flex-grow: 1;
		width: 100%;
		width: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: 30px;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}
}
