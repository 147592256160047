@mixin dsk-small {
	@media screen and (max-width: 360px) {
		@content;
	}
}

@mixin tablet {
	@media screen and (min-width: 400px) {
		@content;
	}
}

@mixin mobile-small {
	@media screen and (max-width: #{$width-mobile-small - 1}px) {
		@content;
	}
}
