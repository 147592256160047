@import 'assets/styles/_imports';

.poll-results {
	$block: '.poll-results';

	max-width: 310px;
	min-width: 214px;

	&__text,
	&__subtext {
		word-break: break-word;
	}

	&__text {
		margin-bottom: 2px;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textPrimary;
	}

	&__subtext {
		margin-bottom: 8px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: $textPrimaryOpacity70;
	}

	&__options {
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__option {
		display: flex;
		position: relative;
		width: 100%;
		height: 42px;
		margin-bottom: 8px;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__option-img {
		width: 42px;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 5px;

		& + #{$block}__option-content {
			width: calc(100% - 42px);
			padding-left: 6px;
		}
	}

	&__option-content {
		width: 100%;
	}

	&__option-title {
		margin-bottom: 3px;
		color: $textPrimary;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__votes {
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
		color: $textPrimaryOpacity70;
	}
}
