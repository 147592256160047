@import 'assets/styles/imports';

.event {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $backgroundPrimary;
	z-index: 100000;
	padding: 16px;
	padding-top: 24px;
	display: flex;
	flex-direction: column;

	&--min {
		position: relative;
		height: auto;
		margin-top: 44px;
		z-index: 1;

		& + .chat {
			.chat__messages-preloader {
				position: absolute;
				top: 0;
			}
		}
	}

	&__title {
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;
		color: $textPrimary;
		text-align: center;

		@include dsk-small {
			font-size: 14px;
			line-height: 16px;
		}

		@include tablet {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__container {
		margin: auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__name {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		color: $textPrimary;
		margin-bottom: 20px;
	}

	&__countdown {
		display: flex;
		flex-direction: column;
		background: #38383d;
		box-shadow: 0px 0px 1.2828px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		padding: 23px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__countdown-title {
		font-size: 13px;
		line-height: 20px;
		font-weight: 400;
		color: rgba(214, 214, 214, 0.8);
		margin-bottom: 16px;
	}

	&__countdown-time {
		display: flex;
		align-items: center;
	}

	&__countdown-delimeter {
		font-size: 16px;
		line-height: 19px;
		font-weight: normal;
		color: #afafaf;
		margin: 0 8px;
		margin-bottom: 9px;
	}

	&__countdown-item {
		width: 67px;
		height: 49px;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
		border-radius: 8px;
		background-color: #525252;
		margin-bottom: 9px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__countdown-number {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		color: $textPrimary;
		margin-bottom: 1px;
	}

	&__countdown-text {
		font-size: 9px;
		line-height: 11px;
		font-weight: 400;
		color: #cccccc;
	}

	&__loading {
		position: absolute;
		top: calc(50% - 21px);
		left: calc(50% - 21px);
		animation: rotating 2s linear infinite;
		width: 42px;
		height: 42px;
		background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 0.375C19.4812 0.375 18.25 1.60622 18.25 3.125V11.375C18.25 12.8938 19.4812 14.125 21 14.125C22.5188 14.125 23.75 12.8938 23.75 11.375V3.125C23.75 1.60622 22.5188 0.375 21 0.375ZM35.5841 6.41593C34.5101 5.34198 32.7689 5.34198 31.695 6.41593L25.8614 12.2496C24.7874 13.3235 24.7874 15.0647 25.8614 16.1386C26.9353 17.2126 28.6765 17.2126 29.7504 16.1386L35.5841 10.305C36.658 9.23107 36.658 7.48987 35.5841 6.41593ZM38.875 18.25C40.3938 18.25 41.625 19.4812 41.625 21C41.625 22.5188 40.3938 23.75 38.875 23.75H30.625C29.1062 23.75 27.875 22.5188 27.875 21C27.875 19.4812 29.1062 18.25 30.625 18.25H38.875ZM35.5841 35.5841C36.658 34.5101 36.658 32.7689 35.5841 31.695L29.7504 25.8614C28.6765 24.7874 26.9353 24.7874 25.8614 25.8614C24.7874 26.9353 24.7874 28.6765 25.8614 29.7504L31.695 35.5841C32.7689 36.658 34.5101 36.658 35.5841 35.5841ZM18.25 30.625C18.25 29.1062 19.4812 27.875 21 27.875C22.5188 27.875 23.75 29.1062 23.75 30.625V38.875C23.75 40.3938 22.5188 41.625 21 41.625C19.4812 41.625 18.25 40.3938 18.25 38.875V30.625ZM16.1387 25.8614C15.0647 24.7874 13.3235 24.7874 12.2496 25.8614L6.41594 31.695C5.34199 32.769 5.34199 34.5102 6.41594 35.5841C7.48988 36.658 9.23108 36.658 10.305 35.5841L16.1387 29.7505C17.2126 28.6765 17.2126 26.9353 16.1387 25.8614ZM11.375 18.25C12.8938 18.25 14.125 19.4812 14.125 21C14.125 22.5188 12.8938 23.75 11.375 23.75H3.125C1.60622 23.75 0.375 22.5188 0.375 21C0.375 19.4812 1.60622 18.25 3.125 18.25H11.375ZM16.1387 16.1386C17.2126 15.0647 17.2126 13.3235 16.1387 12.2495L10.305 6.41589C9.23109 5.34195 7.48988 5.34195 6.41594 6.41589C5.342 7.48984 5.342 9.23104 6.41594 10.305L12.2496 16.1386C13.3235 17.2126 15.0647 17.2126 16.1387 16.1386Z' fill='white'/%3E%3C/svg%3E%0A");
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
