$primary: #ff3f2a;

$textPrimary: rgba(255, 255, 255, 1);
$textPrimaryOpacity80: rgba(255, 255, 255, 0.8);
$textPrimaryOpacity70: rgba(255, 255, 255, 0.7);
$textPrimaryOpacity60: rgba(255, 255, 255, 0.6);
$textPrimaryOpacity40: rgba(255, 255, 255, 0.4);
$textPrimaryOpacity30: rgba(255, 255, 255, 0.3);
$textPrimaryOpacity20: rgba(255, 255, 255, 0.2);
$textPrimaryOpacity10: rgba(255, 255, 255, 0.1);

$textSecond: rgba(0, 0, 0, 1);
$textSecondOpacity80: rgba(0, 0, 0, 0.8);
$textSecondOpacity60: rgba(0, 0, 0, 0.6);
$textSecondOpacity40: rgba(0, 0, 0, 0.4);

$textThird: rgba(29, 31, 35, 1);

$backgroundPrimary: #151417;
$backgroundInverse: #ffffff;
$backgroundInverseOpacity80: rgba(255, 255, 255, 0.8);
$backgroundInverseOpacity60: rgba(255, 255, 255, 0.6);
$backgroundInverseOpacity40: rgba(255, 255, 255, 0.4);
$backgroundInverseOpacity20: rgba(255, 255, 255, 0.2);
$backgroundInverseOpacity12: rgba(255, 255, 255, 0.12);
$backgroundInverseOpacity10: rgba(255, 255, 255, 0.1);
$backgroundInverseOpacity06: rgba(255, 255, 255, 0.06);
$backgroundInverseOpacityZero: rgba(255, 255, 255, 0);

$backgroundSecond: #1d1f23;

$borderPrimaryColor: rgba(255, 255, 255, 1);
$borderPrimaryColorOpacity60: rgba(255, 255, 255, 0.6);
$borderPrimaryColorOpacity40: rgba(255, 255, 255, 0.4);
$borderPrimaryColorOpacity20: rgba(255, 255, 255, 0.2);
$borderPrimaryColorOpacity12: rgba(255, 255, 255, 0.12);
$borderPrimaryColorOpacity10: rgba(255, 255, 255, 0.1);
$borderPrimaryColorOpacity06: rgba(255, 255, 255, 0.06);

$danger: #e50914;
$success: #3dbb3d;
$purple: rgba(123, 56, 165, 1);
$blue: #006cff;
$black: #000000;
