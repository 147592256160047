@import 'assets/styles/_imports';

.tooltip {
	$block: '.tooltip';

	position: absolute;
	background-color: $primary;
	max-width: 230px;
	display: flex;
	padding: 8px;
	border-radius: 10px;
	z-index: -1;
	transition: $duration opacity;
	opacity: 0;

	&--top {
		bottom: calc(100% + 12px);

		#{$block}__arrow {
			right: 8px;
			bottom: -7px;
			transform: rotate(180deg);
		}
	}

	&--bottom {
		top: calc(100% + 12px);

		#{$block}__arrow {
			left: 8px;
			top: -7px;
		}
	}

	&__arrow {
		position: absolute;
		width: 20px;
		height: 8px;
		right: 8px;
		bottom: -7px;
		background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 8' style='enable-background:new 0 0 20 8;' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FF3F2A;' d='M10,0C7,0,4,8,0,8h20C16,8,13,0,10,0z'/%3E%3C/g%3E%3C/svg%3E%0A");
		background-size: cover;
		background-position: center;
	}

	&__text {
		width: 100%;
		white-space: nowrap;
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		color: $textPrimary;
		overflow: hidden;
	}
}
