@import 'assets/styles/_imports';

.users {
	$block: '.users';

	position: relative;
	padding: 5px;
	background-color: #212023;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	transition: background $duration, box-shadow $duration;
	border-radius: 19px;
	user-select: none;

	&--have-speakers {
		padding: 2px 4px;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);

		#{$block}__counter {
			height: 24px;
			border-color: transparent;
		}
	}

	&__counter {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 28px;
		padding: 0 10px;
		border-radius: 20px;
		background: rgba(255, 255, 255, 0.06);

		svg {
			width: 19px;
			height: 16px;
			margin-right: 4px;
		}
	}

	&__counter-number {
		font-size: 13px;
		line-height: 17px;
		color: $textPrimaryOpacity60;
		font-variant-numeric: tabular-nums;
	}

	&__click-area {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
		z-index: 1;
	}

	&__list {
		display: flex;
		flex-direction: row;
	}

	&__list-item {
		position: relative;
		margin-right: 4px;
		border-radius: 50%;
		padding: 2px;
		border: 1px solid transparent;

		&--active {
			border-color: $primary;
		}
	}

	&__list-item-img {
		width: 26px;
		height: 26px;
		border-radius: 50%;
	}
}
