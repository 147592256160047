@import '../imports';

html,
body,
#root {
	background: $backgroundPrimary;
	color: #fff;
	width: 100%;
	height: 100%;
}

#root {
	position: relative;
	overflow: hidden;
}

html {
	font: 400 16px/21px $font-Montserrat;
}

.link {
	color: $primary;
}

.main-page {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: 1;
	overflow: hidden;

	&--show-record {
		.chat {
			margin-bottom: 40px;
		}
	}
}

.app-close-button {
	display: block;
	width: 24px;
	height: 24px;
	position: fixed;
	top: 20px;
	right: 15px;
	padding: 0;
	background: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.00195312' width='24' height='24' rx='12' fill='%23212023'/%3E%3Cpath d='M9.54891 8.73884C9.32576 8.51568 8.96395 8.51568 8.74079 8.73884C8.51764 8.962 8.51764 9.32381 8.74079 9.54696L11.1939 12L8.74079 14.4531C8.51764 14.6763 8.51764 15.0381 8.74079 15.2612C8.96395 15.4844 9.32576 15.4844 9.54891 15.2612L12.002 12.8082L14.4551 15.2612C14.6782 15.4844 15.04 15.4844 15.2632 15.2612C15.4864 15.0381 15.4864 14.6763 15.2632 14.4531L12.8101 12L15.2632 9.54696C15.4864 9.32381 15.4864 8.962 15.2632 8.73884C15.04 8.51568 14.6782 8.51568 14.4551 8.73884L12.002 11.1919L9.54891 8.73884Z' fill='white'/%3E%3C/svg%3E%0A")
		no-repeat center;
	border-radius: 50%;
	z-index: 501;
}
