@import 'assets/styles/imports';

.link-preview-sceleton {
	width: 328px;
	margin-top: 8px;
	border-radius: 6px;

	@media screen and (max-width: 438px) {
		width: 294px;
	}

	@media screen and (max-width: 406px) {
		width: 238px;
	}

	@media screen and (max-width: 356px) {
		width: 204px;
	}

	&__container {
		margin-bottom: 2px;
	}
}

.react-loading-skeleton {
	--base-color: #8c8c8c;
	--highlight-color: #666;
}

.chat__message-container--right {
	.react-loading-skeleton {
		--base-color: #0da4ff;
		--highlight-color: #0d6eff;
	}
}
