@import 'assets/styles/_imports';

.poll-answers {
	$block: '.poll-answers';

	background-color: $backgroundPrimary;

	&__title {
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.3px;
		color: $textPrimaryOpacity60;
		margin-bottom: 8px;
	}

	&__list {
		border: 1px solid $borderPrimaryColorOpacity20;
		border-radius: 8px;
		margin-bottom: 8px;
		display: flex;
		flex-direction: column;

		&--hide-button {
			#{$block}__element:last-child {
				#{$block}__element-wrapper {
					border-bottom-color: transparent;
				}
			}
		}
	}

	&__list-dnd-container {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__element {
		display: flex;
		align-items: center;
		height: 47px;
		padding-left: 15px;
	}

	&__radio {
		position: relative;
	}

	&__radio-input {
		width: 0;
		height: 0;
		display: none;
		visibility: hidden;
		opacity: 0;

		&:checked {
			& + #{$block}__radio-label {
				background-color: $primary;
				background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.83183 10C4.13093 10 4.3623 9.87334 4.52596 9.62579L9.8307 1.19747C9.94921 1.00748 10 0.846287 10 0.690846C10 0.282096 9.71783 0 9.31151 0C9.03499 0 8.86569 0.103627 8.69639 0.374208L3.80361 8.29016L1.29233 5.00288C1.12302 4.77835 0.948081 4.68624 0.699774 4.68624C0.293454 4.68624 0 4.97985 0 5.38284C0 5.56131 0.0620768 5.73402 0.208804 5.91249L3.13205 9.63155C3.32957 9.88486 3.53837 10 3.83183 10Z' fill='white'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__radio-label {
		display: block;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		border: 2px solid $primary;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 20px;
		margin-left: 5px;
		cursor: pointer;
	}

	&__element-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 47px;
		border-bottom: 1px solid $backgroundInverseOpacity20;
	}

	&__img-preview {
		width: 47px;
		height: 47px;
		flex-shrink: 0;
		object-fit: cover;
		margin-right: 12px;
	}

	&__input {
		font-size: 15px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimary;
		letter-spacing: -0.23px;
		margin-right: 14px;
		width: 100%;
	}

	&__btns {
		display: flex;
		flex-shrink: 0;
	}

	&__file {
		display: block;
		width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.25 7.5C14.1808 7.5 12.5 9.17917 12.5 11.25C12.5 13.3208 14.1808 15 16.25 15C18.3192 15 20 13.3208 20 11.25C20 9.17917 18.3192 7.5 16.25 7.5ZM18.3333 11.6667H16.6667V13.3333H15.8333V11.6667H14.1667V10.8333H15.8333V9.16667H16.6667V10.8333H18.3333V11.6667ZM3.33333 11.6667L6.66667 6.7L8.73333 8.33333L10.8333 5L12.3775 7.47C11.3183 8.55583 10.73 10.0442 10.855 11.6667H3.33333ZM12.35 15H0V0H18.3333V6.25083C17.8142 6.03333 17.2525 5.89917 16.6667 5.85417V1.66667H1.66667V13.3333H11.2517C11.5117 13.9575 11.8892 14.52 12.35 15ZM4.58333 5.83333C3.89333 5.83333 3.33333 5.27417 3.33333 4.58333C3.33333 3.89333 3.89333 3.33333 4.58333 3.33333C5.27333 3.33333 5.83333 3.89333 5.83333 4.58333C5.83333 5.27417 5.27333 5.83333 4.58333 5.83333Z' fill='white' fill-opacity='0.4'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		margin-right: 20px;
		flex-shrink: 0;
	}

	&__file-input {
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;
		display: none;
	}

	&__dragger {
		display: block;
		width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='22' y1='6' x2='2' y2='6' stroke='white' stroke-opacity='0.4' stroke-width='2'/%3E%3Cline x1='22' y1='12' x2='2' y2='12' stroke='white' stroke-opacity='0.4' stroke-width='2'/%3E%3Cline x1='22' y1='18' x2='2' y2='18' stroke='white' stroke-opacity='0.4' stroke-width='2'/%3E%3C/svg%3E%0A");
		margin-right: 9px;
		flex-shrink: 0;
	}

	&__new-field {
		height: 47px;
		width: 100%;
		font-size: 15px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimaryOpacity40;
	}

	&__warning {
		font-size: 13px;
		line-height: 18px;
		font-weight: 400;
		color: $textPrimaryOpacity40;
		margin-bottom: 16px;
	}

	&__radio-tip {
		position: absolute;
		top: calc(100% + 14.5px);
		left: -7px;
		padding: 8px 12px;
		background-color: $primary;
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		color: $textPrimary;
		border-radius: 10px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
		white-space: nowrap;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			width: 20px;
			height: 8px;
			left: 12px;
			top: -8px;
			background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 8' style='enable-background:new 0 0 20 8;' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill-rule:evenodd;clip-rule:evenodd;fill:%23FF3F2A;' d='M10,0C7,0,4,8,0,8h20C16,8,13,0,10,0z'/%3E%3C/g%3E%3C/svg%3E%0A")
				no-repeat center;
			background-size: cover;
		}
	}

	&__input-length {
		padding-top: 4px;
		font-size: 13px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimaryOpacity60;
		margin-right: 10px;

		&--out {
			position: absolute;
			transform: translateX(-50%);
			top: -28px;
			right: 8px;
			margin-right: 0;
		}
	}
}
