@import 'assets/styles/_imports';

.poll-question {
	&__title {
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.3px;
		color: $textPrimaryOpacity60;
		margin-bottom: 8px;
	}

	&__input-container {
		position: relative;
		margin-bottom: 16px;
	}

	&__input {
		width: 100%;
		height: 48px;
		padding: 14px 16px;
		line-height: 20px;
		border-radius: 8px;
		border: 1px solid $borderPrimaryColorOpacity20;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimary;
		overflow: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;
		resize: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__icounter {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 13px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimaryOpacity20;

		&--top {
			top: -18px;
		}
	}
}
