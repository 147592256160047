@import 'assets/styles/_imports';

.btn {
	$block: '.btn';

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 12px;
	font-weight: 700;
	font-size: 17px;
	line-height: 24px;
	letter-spacing: -0.4px;
	color: $textPrimary;
	background: linear-gradient(97.3deg, #ff3f2a 5.68%, #ff6644 94.32%);
	border: none;
	border-radius: 20px;
	transition: color $duration, background $duration;
	user-select: none;
	outline: none;

	@include tablet {
		width: 100%;
		max-width: 100%;
	}

	&--dark {
		background-color: $backgroundPrimary;
		color: $textPrimary;
	}

	&[disabled] {
		background: #2b2a2f;
		color: #59595d;
	}

	&--poll {
		position: relative;
		width: 100%;
	}

	&--poll#{&}--disabled {
		background: #2b2a2f;
		color: #59595d;
	}

	&--sm {
		font-size: 12px;
		line-height: 16px;
		padding: 8px;
		border-radius: 4px;
	}
}
