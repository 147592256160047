@import 'assets/styles/_imports';

.select {
	$block: '.select';

	position: relative;
	width: 100%;

	&--opened {
		#{$block}__toggle {
			&::after {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}

	&__toggle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 13px 40px 11px 12px;
		text-align: left;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
		background: rgba(255, 255, 255, 0.06);
		border: none;
		border-radius: 12px;
		transition: background $duration;
		user-select: none;

		&:hover {
			background: $backgroundInverseOpacity12;
		}

		&:active {
			background: $backgroundInverseOpacity20;
		}

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:after {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 16px;
			width: 9px;
			height: 16px;
			background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.74408 6.49408C7.41864 6.16864 7.41864 5.641 7.74408 5.31557L7.81557 5.24408C8.141 4.91864 8.66864 4.91864 8.99408 5.24408L12.643 8.89298C13.2938 9.54385 13.2939 10.5991 12.643 11.25L8.99408 14.8989C8.66864 15.2243 8.141 15.2243 7.81557 14.8989L7.74408 14.8274C7.41864 14.502 7.41864 13.9743 7.74408 13.6489L10.7322 10.6607C11.0577 10.3353 11.0577 9.80767 10.7322 9.48223L7.74408 6.49408Z' fill='white'/%3E%3C/svg%3E%0A")
				no-repeat center;
			transform: translateY(-50%) rotate(90deg);
			z-index: 1;
		}

		span {
			font-weight: 500;
			font-size: 13px;
			line-height: 18px;
			text-align: right;
			letter-spacing: -0.08px;
			color: $textPrimaryOpacity40;
		}
	}

	&__dropdown {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		background-color: #343434;
		border-radius: 16px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
		z-index: 2;
	}

	&__axis-y {
		flex-grow: 1;
		width: 100%;
		max-height: 300px;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__items {
		padding: 6px 0;
	}

	&__item {
		&:not(:last-child) {
			border-bottom: 1px solid rgba(84, 84, 88, 0.65);
		}
	}

	&__item-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 13px 40px 11px 12px;
		text-align: left;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
		background-color: #343434;
		border: none;
		border-radius: 12px;
		user-select: none;

		&--selected {
			&:after {
				@include ps-elem;
				position: absolute;
				top: 50%;
				right: 16px;
				flex-shrink: 0;
				width: 18px;
				height: 18px;
				margin-left: 12px;
				background: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.89729 18C7.43567 18 7.85214 17.772 8.14673 17.3264L17.6953 2.15544C17.9086 1.81347 18 1.52332 18 1.24352C18 0.507772 17.4921 0 16.7607 0C16.263 0 15.9582 0.186529 15.6535 0.673575L6.8465 14.9223L2.32619 9.00518C2.02144 8.60104 1.70655 8.43523 1.25959 8.43523C0.528217 8.43523 0 8.96373 0 9.68912C0 10.0104 0.111738 10.3212 0.375847 10.6425L5.6377 17.3368C5.99323 17.7927 6.36907 18 6.89729 18Z' fill='%23ff3f2a'/%3E%3C/svg%3E%0A")
					no-repeat center;
				transform: translateY(-50%);
			}
		}
	}
}
